<template>
  <nav class="ns-nav">
    <div :class="['ns-nav__nav-links', containerSpacing, headerPadding]">
      <div class="ns-nav__logo-container">
        <router-link
          :class="['ns-nav__item ns-nav__item--logo-link', moveLogoLink]"
          to="/"
        >
          <NSIcon
            style="transition: 0.1s"
            icon="logo"
            :size="logoSize"
            color="var(--logo-vector--dark)"
          />
          <p
            style="margin-left: 0px"
            :class="['ns-nav__logo-name', resizeHeader]"
          >
            Netshore Ltd.
          </p>
        </router-link>
      </div>
      <div class="ns-nav__menu-items ns-nav__menu-items--align-right">
        <router-link :class="['ns-nav__item', resizeHeader]" to="/"
          >About Us</router-link
        >
        <router-link :class="['ns-nav__item', resizeHeader]" to="/ourteam"
          >Our Team</router-link
        >
        <router-link :class="['ns-nav__item', resizeHeader]" to="/jobopenings"
          >Job Openings</router-link
        >
      </div>
    </div>
    <hr class="ns-nav__dividing-line" />
  </nav>
</template>

<script>
import NSIcon from "@/components/ns-icon/NsIcon.vue";
import { inject } from "vue";
export default {
  name: "NsHeader",
  setup() {
    const containerSpacing = inject("containerSpacing", "desktop-spacing");
    const logoSize = inject("logoSize", "var(--font-size-xxlarge)");
    const resizeHeader = inject("resizeHeader", "ns-nav__item--navbar-large");
    const headerPadding = inject(
      "headerPadding",
      "ns-nav__nav-links--add-padding"
    );
    const moveLogoLink = inject("moveLogoLink", "logo-link-inline");
    return {
      containerSpacing,
      logoSize,
      resizeHeader,
      headerPadding,
      moveLogoLink,
    };
  },
  components: {
    NSIcon,
  },
};
</script>

<style scoped>
.ns-nav__nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.ns-nav__item {
  min-width: max-content;
}
.ns-nav__logo-container {
  text-align: center;
  max-width: max-content;
}
.ns-nav__logo-name {
  padding-top: var(--space--1);
  color: var(--logo-text--dark);
  font-weight: var(--font-weight-bold);
  width: max-content;
}
.ns-nav__item:hover {
  color: var(--emphasized-text-dark--secondary);
}
.ns-nav__menu-items--align-right {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
a.router-link-exact-active {
  position: relative;
  z-index: 1;
  color: var(--emphasized-text-dark--secondary);
}
a.router-link-exact-active::after {
  /* content: url("../../assets/decoratives/nuqta.svg"); */
  position: absolute;
  top: 0;
  right: calc(-1 * var(--space--1));
  z-index: -1;
}
a.router-link-exact-active.ns-nav__item--logo-link::after {
  display: none;
}
.ns-nav__dividing-line {
  display: none;
}
.logo-link-inline {
  display: inline;
}
.logo-link-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space--2);
}
.ns-nav__item--navbar-small {
  font-size: var(--font-size-m-xxsmall);
  line-height: var(--line-height-m-xxsmall);
  margin-left: var(--space--6);
}
.ns-nav__item--navbar-large {
  font-size: var(--font-size-m-xsmall);
  line-height: var(--line-height-m-xsmall);
  margin-left: var(--space--6);
}
.ns-nav__nav-links--remove-padding {
  padding-top: var(--space--1);
  padding-bottom: var(--space--1);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.ns-nav__nav-links--add-padding {
  padding-top: var(--space--3);
  padding-bottom: var(--space--3);
}
.ns-nav__item--navbar-large,
.ns-nav__item--navbar-small,
.ns-nav__nav-links--remove-padding,
.ns-nav__nav-links--add-padding {
  transition: 0.1s;
}
@media screen and (max-width: 640px) {
  .ns-nav__nav-links {
    box-shadow: 0px 0px 0px;
  }
  .ns-nav__logo-name {
    font-size: var(--font-size-m-xsmall);
    line-height: var(--line-height-m-xsmall);
  }
  .ns-nav__menu-items {
    font-size: var(--font-size-m-xxsmall);
    line-height: var(--line-height-m-xxsmall);
  }
  a.router-link-exact-active::after {
    top: -2px;
    right: calc(-1 * var(--space--2));
  }
  .ns-nav__menu-items--align-right {
    flex: 100%;
    justify-content: space-between;
    margin-top: var(--space--4);
  }
  .ns-nav__dividing-line {
    display: block;
    border: 0;
    border-top: 1px solid;
    border-color: var(--input-field--normal-color);
    font-size: 0;
  }
  .ns-nav__item--navbar-small {
    margin: var(--space--0);
  }
}
</style>
