<template>
  <div class="ns-app">
    <div class="ns-header" id="go-to-top">
      <NsHeader />
    </div>
    <div class="ns-page">
      <router-view />
    </div>
    <div class="ns-footer">
      <NsFooter />
    </div>
    <a class="ns-app__scroll-to-top" href="#go-to-top">
      <NsIcon
        icon="circle-next"
        color="var(--btn--scroll-to-top)"
        size="2.23em"
      />
    </a>
  </div>
</template>
<script>
import NsHeader from "@/components/ns-header/NsHeader.vue";
import NsFooter from "@/components/ns-footer/NsFooter.vue";
import NsIcon from "@/components/ns-icon/NsIcon.vue";
import { onMounted, onUnmounted, provide, ref } from "vue";

export default {
  components: {
    NsHeader,
    NsFooter,
    NsIcon,
  },
  setup() {
    const containerSpacing = ref(
      window.innerWidth < 641 ? "mobile-spacing" : "desktop-spacing"
    );
    const containerSpacingDesktop = ref(
      window.innerWidth < 641 ? "" : "desktop-spacing"
    );
    const containerSpacingMobile = ref(
      window.innerWidth < 641 ? "mobile-spacing" : ""
    );
    const logoSize = ref(
      window.innerWidth < 641
        ? "var(--font-size-xlarge)"
        : "var(--font-size-xxlarge)"
    );
    const resizeHeader = ref(
      window.innerWidth < 641
        ? "ns-nav__item--navbar-small"
        : "ns-nav__item--navbar-large"
    );
    const headerPadding = ref(
      window.innerWidth < 641
        ? "ns-nav__nav-links--remove-padding"
        : "ns-nav__nav-links--add-padding"
    );
    const moveLogoLink = ref(window.innerWidth < 641 ? "logo-link-inline" : "");

    function resizeHeaderOnScroll() {
      if (window.innerWidth > 640) {
        if (document.documentElement.scrollTop > 120) {
          resizeHeader.value = "ns-nav__item--navbar-small";
          logoSize.value = "var(--font-size-xlarge)";
          headerPadding.value = "ns-nav__nav-links--remove-padding";
          moveLogoLink.value = "logo-link-flex";
        }
        if (document.documentElement.scrollTop < 50) {
          resizeHeader.value = "ns-nav__item--navbar-large";
          logoSize.value = "var(--font-size-xxlarge)";
          headerPadding.value = "ns-nav__nav-links--add-padding";
          moveLogoLink.value = "logo-link-inline";
        }
      }
    }
    function onResize() {
      if (window.innerWidth <= 640) {
        containerSpacing.value = "mobile-spacing";
        containerSpacingDesktop.value = "";
        containerSpacingMobile.value = "mobile-spacing";
        logoSize.value = "var(--font-size-xlarge)";
        resizeHeader.value = "ns-nav__item--navbar-small";
        headerPadding.value = "ns-nav__nav-links--remove-padding";
        moveLogoLink.value = "logo-link-inline";
      }
      if (window.innerWidth > 640) {
        containerSpacing.value = "desktop-spacing";
        containerSpacingDesktop.value = "desktop-spacing";
        containerSpacingMobile.value = "";
        if (document.documentElement.scrollTop < 50) {
          resizeHeader.value = "ns-nav__item--navbar-large";
          headerPadding.value = "ns-nav__nav-links--add-padding";
          logoSize.value = "var(--font-size-xxlarge)";
          moveLogoLink.value = "logo-link-inline";
        }
      }
    }

    onMounted(() => window.addEventListener("resize", onResize));
    onMounted(() => window.addEventListener("scroll", resizeHeaderOnScroll));
    onUnmounted(() => window.removeEventListener("resize", onResize));
    onUnmounted(() =>
      window.removeEventListener("scroll", resizeHeaderOnScroll)
    );
    provide("containerSpacing", containerSpacing);
    provide("containerSpacingDesktop", containerSpacingDesktop);
    provide("containerSpacingMobile", containerSpacingMobile);
    provide("logoSize", logoSize);
    provide("resizeHeader", resizeHeader);
    provide("headerPadding", headerPadding);
    provide("moveLogoLink", moveLogoLink);
    return {
      containerSpacing,
      containerSpacingDesktop,
      containerSpacingMobile,
      logoSize,
      resizeHeader,
      headerPadding,
      moveLogoLink,
    };
  },
};
</script>

<style scoped>
.ns-app {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: var(--space--0) auto;
  background-color: var(--white--color);
}
.ns-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
  background-color: var(--background-white);
}
.ns-page {
  margin-top: var(--space--11);
  min-height: 80vh;
}
.ns-footer {
  margin-top: var(--space--19);
}
.ns-app__scroll-to-top {
  display: none;
}
@media screen and (max-width: 640px) {
  .ns-header {
    position: unset;
  }
  .ns-page {
    margin-top: var(--space--6);
  }
  .ns-footer {
    margin-top: var(--space--9);
  }
  .ns-app__scroll-to-top {
    display: block;
    position: fixed;
    bottom: var(--space--4);
    right: var(--space--4);
    transform: rotateZ(-90deg);
    z-index: 5;
  }
}
</style>
