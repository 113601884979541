<template>
  <button :disabled="disabled" :class="[btnClasses]">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
    },
    primary: {
      type: Boolean,
    },
    secondary: {
      type: Boolean,
    },
    bordered: {
      type: Boolean,
    },
    ghost: {
      type: Boolean,
    },
  },
  computed: {
    btnClasses() {
      return [
        this.primary && "btn--primary ",
        this.secondary && "btn--secondary",
        this.bordered && "btn--border",
        this.ghost && "btn--ghost",
      ];
    },
  },
};
</script>

<style scoped>
.btn--primary {
  color: var(--btn-normal-light--text);
  background: var(--btn-normal-dark--primary);
  box-shadow: 3px 3px 0px var(--btn-normal--shadow);
}
.btn--primary:hover {
  background: var(--btn-hover-dark--primary);
}

.btn--primary:focus {
  background: var(--btn-hover-dark--primary);
}

.btn--secondary {
  color: var(--btn-normal-dark--text);
  background: var(--white--color);
}

.btn--secondary {
  box-shadow: 2px 2px 0px var(--btn-normal--shadow);
}

.btn--primary:hover,
.btn--secondary:hover {
  box-shadow: 4px 4px 0px var(--btn-hover--shadow);
}

.btn--primary:focus,
.btn--secondary:focus {
  box-shadow: 4px 4px 0px var(--btn-focus--shadow);
}

.btn--primary:disabled,
.btn--secondary:disabled {
  background: var(--btn-disabled-dark);
  color: var(--color-black--200);
  box-shadow: 3px 3px 0px var(--btn-disabled--shadow);
}

.btn--border {
  border: 2px solid;
  border-color: var(--btn-outline--dark);
}

.btn--ghost {
  border: 1px solid var(--btn-outline--light);
  background: transparent;
  color: var(--btn-normal-cream--text);
}
.btn--ghost:hover {
  background: var(--btn-hover-dark--secondary);
  border: 1px solid var(--btn-outline--light);
}

.btn--ghost:focus {
  background: var(--btn-focus-dark--secondary);
  border: 2px solid var(--btn-outline--light);
}

.btn--ghost:disabled {
  background: transparent;
  border: 1px solid var(--btn-disabled--text);
  color: var(--btn-disabled--text);
}
</style>
