<template>
  <div :class="['footer', containerSpacing]">
    <div class="footer__data">
      <router-link to="/">
        <NsIcon class="footer__logo" icon="logo" :size="logoSize" />
      </router-link>
      <p class="footer__patnership-info">
        We have also partnered with a Swedish software company (Netset AB) and
        inherited their development processes in order to meet our customers'
        high standards.
      </p>
      <div class="footer__links">
        <ul class="footer__site-links">
          <li class="remove-gap">
            <router-link class="footer__site-link" to="/">About us</router-link>
          </li>
          <li class="remove-gap">
            <router-link to="/ourteam" class="footer__site-link"
              >Our Team</router-link
            >
          </li>
          <li class="remove-gap">
            <router-link to="/contactus" class="footer__site-link"
              >Contact us</router-link
            >
          </li>
        </ul>
        <ul class="footer__social-links">
          <li class="footer__social-link remove-gap">
            <a
              rel="noreferrer"
              target="_blank"
              class="footer__site-link"
              href="https://www.linkedin.com/company/netshore/"
              ><div class="footer_site-link--align-top">
                <nsIcon icon="linkedin" size="1.52em" />
              </div>
              NetshorePk</a
            >
          </li>
        </ul>
      </div>
      <p class="footer__address">
        Tel:
        <a class="footer__phone-address" href="tel:+9251 8356 207"
          >+9251 8356 207</a
        >
        <br />
        1st Floor, Lake Vista Heights, Main Bahria Expy, Phase-8, Bahria Town,
        Rawalpindi
      </p>
    </div>
  </div>
</template>
<script>
import NsIcon from "@/components/ns-icon/NsIcon.vue";
import { inject } from "vue";
export default {
  name: "NsFooter",
  setup() {
    const logoSize = inject("logoSize", "var(--font-size--xxlarge)");
    const containerSpacing = inject("containerSpacing", "desktop-spacing");

    return {
      logoSize,
      containerSpacing,
    };
  },
  components: {
    NsIcon,
  },
};
</script>
<style scoped>
.remove-gap {
  margin-bottom: var(--space--4);
  width: max-content;
}
.footer {
  background: var(--background-dark--secondary);
}
.footer__data {
  padding-top: var(--space--19);
  padding-bottom: var(--space--11);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.footer__patnership-info {
  min-width: 320px;
  flex: 1;
  letter-spacing: var(--letter-spacing-normal);
  margin: var(--space--0) var(--space--7) var(--space--7) var(--space--7);
}
.footer__logo {
  color: var(--btn-normal-light--text);
}
.footer__patnership-info {
  color: var(--normal-text--light);
}
.footer__links {
  display: flex;
  justify-content: space-evenly;
  flex: 1;
  color: var(--anchor-text--light);
}
.footer__site-links,
.footer__social-links {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: var(--space--0);
}
.footer__site-link {
  width: max-content;
  color: inherit;
  display: flex;
}
.footer_site-link--align-top {
  margin-top: calc(-1 * var(--space--1));
  margin-right: var(--space--2);
}
.footer__site-link:hover {
  text-decoration: underline;
  color: var(--anchor-text--active-light);
}
.footer__address {
  flex: 100%;
  margin-top: var(--space--14);
  font-size: var(--font-size-xxsmall);
  line-height: var(--line-height-xxsmall);
}
.footer__address,
.footer__phone-address {
  color: var(--footer-address-color);
  letter-spacing: var(--letter-spacing-normal);
}
@media screen and (max-width: 1024px) {
  .footer__data {
    padding-top: var(--space--6);
    padding-bottom: var(--space--8);
  }
  .footer__patnership-info {
    font-size: var(--font-size-m-xsmall);
    line-height: var(--line-height-m-xsmall);
    flex: 100%;
    min-width: auto;
    margin: var(--space--4) var(--space--0) var(--space--6) var(--space--0);
    letter-spacing: var(--letter-spacing-large);
  }
  .footer__links {
    font-size: var(--font-size-m-xsmall);
    line-height: var(--line-height-m-xsmall);
    justify-content: space-between;
  }
  .footer__site-link {
    width: auto;
  }
  .footer__address {
    margin-top: var(--space--3);
  }
}
</style>
