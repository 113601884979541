<template>
  <div :class="['ns-not-found-page', containerSpacing]">
    <NsHero
      title="404! Something wrong happened"
      :subtitle="'Don’t need to worry, just' + goToBack"
      :img="require('../assets/images/hero/404.svg')"
    />
  </div>
</template>
<script>
import NsHero from "@/components/ns-hero/NsHero.vue";
import { inject } from "vue";

export default {
  components: {
    NsHero,
  },
  name: "NotFound",
  data() {
    return {
      goToBack:
        '<a href="/" style="color:var(--link-color-dark--secondary);font-weight:var(--font-weight-bold); text-decoration: underline;font-family:var(--headings-and-emphasized--font-family)"> click here to go back</a>',
    };
  },
  setup() {
    const containerSpacing = inject("containerSpacing", "desktop-spacing");
    return {
      containerSpacing,
    };
  },
};
</script>
<style scoped>
.ns-not-found-page {
  margin-bottom: var(--space--8);
  display: flex;
  justify-content: center;
}
</style>
