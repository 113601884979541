<template>
  <svg>
    <use :xlink:href="src" />
  </svg>
</template>

<script>
import spriteIcons from "../../assets/icons/sprite.svg";

export default {
  name: "NSIcon",
  props: {
    icon: {
      type: String,
      default: "",
    },

    color: {
      type: String,
      default: "currentColor",
    },

    size: {
      type: String,
      default: "1em",
    },
  },
  computed: {
    src() {
      return spriteIcons + "#" + this.icon;
    },
  },
};
</script>

<style scoped>
svg {
  width: v-bind(size);
  height: v-bind(size);
  fill: v-bind(color);
  vertical-align: middle;
}
</style>
