<template>
  <div class="ns-about">
    <div class="hero-section">
      <img
        class="hero-section__scribble-cursor"
        src="../assets/decoratives/scribble-cursor.svg"
        alt="scribble cursor"
      />
      <img
        data-aos="fade-up-right"
        data-aos-duration="1000"
        data-aos-delay="450"
        data-aos-easing="ease-in-out"
        class="hero-section__scribble-plane"
        src="../assets/decoratives/scribble-plane.svg"
        alt="scribble plane"
      />
      <img
        data-aos="zoom-in"
        data-aos-duration="800"
        data-aos-delay="350"
        data-aos-easing="ease-in-out-back"
        class="hero-section__scribble-group-left"
        src="../assets/decoratives/scribble-stars-yellow.svg"
        alt="scribble stars yellow"
      />
      <img
        data-aos="zoom-in"
        data-aos-duration="800"
        data-aos-easing="ease-in-out-back"
        class="hero-section__scribble-group-right"
        src="../assets/decoratives/scribble-stars-blue.svg"
        alt="scribble stars blue"
      />
      <NsHero
        title="We work for one of Europe’s largest B2B e-commerce firms"
        :img="require('../assets/images/hero/team-developing.svg')"
        :class="[containerSpacingDesktop]"
      >
        <template #extraDetails
          ><p>
            Netshore's objective is to provide a welcoming atmosphere for our
            talented staff so that they can deliver high-quality development
            services from Pakistan. In order to meet our clients' high
            expectations, we've also collaborated with a Swedish software
            company (Netset AB) and inherited their development procedures.
          </p></template
        >
        <template #extraButton>
          <NsButton
            @mouseover="animate = true"
            @mouseleave="animate = false"
            primary
            @click="this.$router.push('/jobopenings')"
            >Work With Netshore<NsIcon
              style="margin-left: var(--space--3)"
              :data-aos="moveArrow()"
              data-aos-duration="400"
              data-aos-easing="ease-in-out"
              icon="right-arrow"
              size="1.5em"
          /></NsButton>
        </template>
      </NsHero>
    </div>

    <div :class="['review-section', containerSpacing]">
      <div class="review-section__review">
        <h4 class="review-section__review--heading">
          <span>What our Clients are saying</span>
        </h4>
        <p class="review-section__review--text text-styling">
          We work with a few prestigious clients. See how they remember us and
          what they look for in our team.
        </p>
        <div class="review-section__review--logos">
          <a href="https://www.netset.com/" target="_blank">
            <img
              class="review-section__review--logos-netset"
              src="../assets/images/about-us/ns-logo.png"
              alt="netset logo"
            />
          </a>
          <a href="https://www.atea.com/" target="_blank">
            <img
              class="review-section__review--logos-atea"
              src="../assets/images/about-us/atea-logo.svg"
              alt="atea logo"
            />
          </a>
        </div>
      </div>
      <div class="review-section__cards">
        <NsClientCard
          class="ns-client-card-margin"
          name="Jens Rosberg"
          info="CEO - Netset"
          bgColor="var(--client-bg-color--secondary)"
          svgColor="var(--client-svg-color--secondary)"
          :img="require('../assets/images/client-card/jens-rosberg.jpeg')"
          x="-43px"
          y="-40px"
          review="Working with such a skilled team was a true pleasure. We worked together to develop industry-leading and world-class e-commerce solutions."
        />
        <NsClientCard
          name="Helene Svensson"
          info="UX Manager - Atea"
          bgColor="var(--client-bg-color--primary)"
          svgColor="var(--client-svg-color--primary)"
          :img="require('../assets/images/client-card/helene-svensson.jpg')"
          x="-43px"
          y="-35px"
          review="Atea has worked with Netset and Netshore for many years with success. We've learned that the most important thing of all is to put the team first. Invest in them and treat them right."
        />
      </div>
    </div>

    <div :class="['vision-section', containerSpacing]">
      <div class="vision-section__vision">
        <h4 class="vision-section--heading">Our Vision</h4>
        <div>
          <p class="vision-section__vision--text text-styling">
            Netshore's vision is to provide a multicultural, multi-faceted
            learning and evolving experience for our team. We love to extend our
            services for the betterment of our customers. From today to 2025,
            our vision is to expand more and let our clients prosper more.
          </p>
          <p class="text-styling">
            We believe that a single idea can change the world. But great ideas
            only matter when they become real, ready-to-launch products and
            services.
          </p>
        </div>
      </div>
      <div class="vision-section__business-philosophy">
        <h4 class="vision-section--heading">Business Philosophy</h4>
        <p class="text-styling">
          Creating a culture of inclusiveness and belonging where all are
          welcome. Exceeding customer expectations in everything we do and
          holding ourselves accountable at every step. We embrace change and
          innovation and look for new ways to grow our organisation and
          ourselves.
        </p>
        <div class="vision-section__business-philosophy--scribble-group">
          <img
            data-aos="zoom-in"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out-back"
            class="scribble-group-width"
            src="../assets/decoratives/scribble-stars-darkblue.svg"
            alt="scribble stars darkblue"
          />
        </div>
      </div>
    </div>

    <div :class="['ethics-section', containerSpacingDesktop]">
      <div class="ethics-section__images">
        <figure class="ethics-section__images--left-img">
          <picture>
            <source
              class="ethics-section__images--width"
              width="438"
              height="329"
              srcset="../assets/images/ethics-img/team-I.webp"
              type="image/webp"
            />
            <img
              class="ethics-section__images--width"
              src="../assets/images/ethics-img/team-I.jpg"
              alt="Netshore team tour to Cherat"
              width="438"
              height="329"
            />
          </picture>
        </figure>
        <figure class="ethics-section__images--right-img">
          <picture>
            <source
              class="ethics-section__images--width"
              width="438"
              height="329"
              srcset="../assets/images/ethics-img/team-II.webp"
              type="image/webp" />
            <img
              class="ethics-section__images--width"
              src="../assets/images/ethics-img/team-II.jpg"
              alt="Netshore team tour to Baku"
              width="438"
              height="329"
          /></picture>
        </figure>
      </div>
      <div :class="['ethics-section__work-ethics', containerSpacingMobile]">
        <h4 class="ethics-section__work-ethics--heading">
          Work Ethics
          <img
            data-aos="zoom-in"
            data-aos-delay="400"
            data-aos-easing="ease-in-out-back"
            class="ethics-section__work-ethics--scribble-mustache"
            src="../assets/decoratives/scribble-mustache-blue.svg"
            alt="mustache"
          />
        </h4>
        <p class="text-styling ethics-section__work-ethics--paragraph">
          We believe in skills and the quality of work. Communication is the key
          in all our job roles. Don’t hesitate to ask your seniors any questions
          you may have in mind.
        </p>
        <ul class="ethics-section__work-ethics--list text-styling">
          <li>
            <NsIcon
              style="margin-right: var(--space--3)"
              icon="nuqta"
              color="var(--nuqta-fill--green)"
              size="1.33em"
            />Transparency
          </li>
          <li>
            <NsIcon
              style="margin-right: var(--space--3)"
              icon="nuqta"
              color="var(--nuqta-fill--blue-green)"
              size="1.33em"
            />Professionalism
          </li>
          <li>
            <NsIcon
              style="margin-right: var(--space--3)"
              icon="nuqta"
              color="var(--nuqta-fill--chocolate-dark)"
              size="1.33em"
            />Open-Minded
          </li>
          <li>
            <NsIcon
              style="margin-right: var(--space--3)"
              icon="nuqta"
              color="var(--nuqta-fill--deep-blue)"
              size="1.33em"
            />Multi-cultural growth
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import NsHero from "@/components/ns-hero/NsHero.vue";
import NsButton from "@/components/ns-button/NsButton.vue";
import NsIcon from "@/components/ns-icon/NsIcon.vue";
import NsClientCard from "@/components/ns-client-card/NsClientCard.vue";
import { inject, ref } from "vue";

export default {
  name: "AboutUs",
  setup() {
    const containerSpacing = inject("containerSpacing", "desktop-spacing");
    const containerSpacingDesktop = inject(
      "containerSpacingDesktop",
      "desktop-spacing"
    );
    const containerSpacingMobile = inject(
      "containerSpacingMobile",
      "mobile-spacing"
    );
    const animate = ref(false);
    const moveArrow = () => {
      return animate.value && "mouse-hover-animation";
    };
    return {
      containerSpacing,
      containerSpacingDesktop,
      containerSpacingMobile,
      animate,
      moveArrow,
    };
  },
  components: {
    NsIcon,
    NsClientCard,
    NsHero,
    NsButton,
  },
};
</script>

<style scoped>
[data-aos="new-animation"] {
  transform: translatex(56px);
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos="new-animation"].aos-animate {
  transform: translateX(0px);
  opacity: 1;
}
[data-aos="mouse-hover-animation"] {
  transform: translateX(12px);
}
.text-styling {
  color: var(--normal-text--dark);
  letter-spacing: var(--letter-spacing-normal);
}
.ns-about {
  display: flex;
  flex-direction: column;
}
.hero-section {
  position: relative;
  margin: var(--space--0) auto;
  z-index: 0;
  margin-bottom: var(--space--11);
}
.hero-section__scribble-cursor {
  top: 18%;
  left: 17%;
  width: 4.5%;
}
.hero-section__scribble-plane {
  top: 23%;
  right: 8%;
  width: 6.6%;
}
.hero-section__scribble-group-left {
  top: 28%;
  left: 7%;
  width: 21%;
}
.hero-section__scribble-group-right {
  top: 46%;
  right: 7.5%;
  width: 31.7%;
}
.hero-section__scribble-cursor,
.hero-section__scribble-plane,
.hero-section__scribble-group-left,
.hero-section__scribble-group-right {
  display: block;
  position: absolute;
  z-index: -1;
}
.review-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--space--13);
}
.review-section__review {
  flex: 1.5;
}
.review-section__review--heading {
  max-width: 274px;
  color: var(--emphasized-text-dark--primary);
}
.review-section__review--text {
  margin-top: var(--space--4);
  margin-bottom: var(--space--3);
}
.review-section__review--logos {
  display: flex;
  align-items: center;
}
.review-section__review--logos-netset {
  width: 130px;
  margin-right: var(--space--3);
}
.review-section__review--logos-atea {
  opacity: 0.24;
  width: 105px;
}
.review-section__cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.ns-client-card-margin {
  margin-right: var(--space--5);
}
.vision-section {
  background: var(--background-light--secondary);
  padding-top: var(--space--11);
  padding-bottom: var(--space--4);
  display: flex;
  margin-bottom: var(--space--14);
}
.vision-section__vision {
  margin-right: var(--space--6);
}
.vision-section--heading {
  position: relative;
  color: var(--emphasized-text-dark--primary);
  margin-bottom: var(--space--4);
}
.vision-section__vision--text {
  margin-bottom: var(--space--3);
}
.vision-section__business-philosophy {
  position: relative;
}
.vision-section__business-philosophy--scribble-group {
  width: 100%;
  text-align: right;
}
.ethics-section {
  display: flex;
  flex: 1;
  align-items: flex-start;
}
.ethics-section__images {
  display: flex;
  border-radius: 8px;
  flex: 1;
}
.ethics-section__images--left-img {
  width: 100%;
  border-radius: inherit;
  margin-right: var(--space--5);
}
.ethics-section__images--right-img {
  padding-top: 6em;
  border-radius: inherit;
  width: 100%;
}
.ethics-section__images--width {
  max-width: 100%;
  border-radius: inherit;
  height: auto;
}
.ethics-section__work-ethics {
  flex: 1.06;
  margin-left: var(--space--6);
}
.ethics-section__work-ethics--heading {
  position: relative;
  color: var(--emphasized-text-dark--secondary);
  display: inline;
}
.ethics-section__work-ethics--paragraph {
  margin: var(--space--5) var(--space--0);
}
.ethics-section__work-ethics--scribble-mustache {
  position: absolute;
  top: calc(-1 * var(--space--4));
  right: calc(-1 * var(--space--6));
  width: 40px;
}
.ethics-section__work-ethics--list {
  list-style: none;
  padding: var(--space--0);
  display: flex;
  flex-wrap: wrap;
}
.ethics-section__work-ethics--list li {
  display: flex;
  align-items: center;
  margin: var(--space--0) var(--space--5) var(--space--5) var(--space--0);
}
.scribble-group-width {
  width: 173px;
}
@media screen and (max-width: 640px) {
  .hero-section {
    margin-bottom: var(--space--9);
  }
  .hero-section__scribble-cursor {
    width: 4%;
    top: 26%;
    left: 0;
  }
  .hero-section__scribble-plane {
    top: 28%;
    right: 0;
  }
  .hero-section__scribble-group-left {
    top: 38%;
  }
  .hero-section__scribble-group-right {
    top: 46%;
    right: 2.5%;
  }
  .vision-section {
    flex-wrap: wrap;
    margin-bottom: var(--space--9);
  }
  .vision-section__vision {
    margin-right: var(--space--0);
    margin-bottom: var(--space--5);
  }
  .vision-section--heading,
  .review-section__review--heading,
  .ethics-section__work-ethics--heading {
    font-size: var(--font-size-m-large);
    line-height: var(--line-height-m-large);
  }
  .vision-section__vision--text {
    margin-bottom: var(--space--0);
  }
  .text-styling {
    font-size: var(--font-size-m-xsmall);
    line-height: var(--line-height-m-xsmall);
    letter-spacing: var(--letter-spacing-large);
  }
  .scribble-group-width {
    width: 130px;
  }
  .ethics-section {
    flex-direction: column-reverse;
    column-gap: var(--space--0);
  }
  .ethics-section__work-ethics {
    margin-bottom: var(--space--5);
  }
  .ethics-section__work-ethics--scribble-3line {
    width: 31.49px;
    top: -18px;
    right: -29px;
  }
  .ethics-section__work-ethics--list {
    flex-direction: column;
  }
  .ethics-section__images {
    flex-wrap: wrap;
    width: 100%;
  }
  .ethics-section__images--width {
    width: 70%;
    border: inherit;
  }
  .ethics-section__images--left-img {
    border-radius: 0px 8px 8px 0px;
    margin-right: var(--space--0);
    margin-bottom: var(--space--5);
  }
  .ethics-section__images--right-img {
    padding-top: var(--space--0);
    text-align: right;
    border-radius: 8px 0px 0px 8px;
  }
  .ethics-section__work-ethics--scribble-mustache {
    width: 32px;
    top: calc(-1 * var(--space--3));
    right: calc(-1 * var(--space--5));
  }
  .review-section__cards {
    flex-direction: column;
  }
  .review-section__review--logos {
    flex-wrap: wrap;
  }
  .review-section__review--logos-atea {
    width: 84px;
  }
  .review-section__review--logos-netset {
    width: 105px;
  }
  .review-section__review {
    flex: 100%;
    margin: var(--space--0) var(--space--0) var(--space--4) var(--space--0);
  }
}

@media screen and (min-width: 640px) and (max-width: 767px) {
  .review-section__cards {
    flex-direction: column;
  }
  .review-section__review {
    margin-bottom: var(--space--5);
  }
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .ethics-section {
    flex-direction: column-reverse;
    align-items: stretch;
    column-gap: var(--space--0);
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .review-section__cards {
    flex: 100%;
  }
  .review-section__review {
    flex: 100%;
  }
  .ns-clientcard {
    max-width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .review-section__review {
    margin: var(--space--0) var(--space--5) var(--space--5) var(--space--0);
  }
}
@media screen and (max-width: 1024px) {
  .review-section__review {
    margin: var(--space--0) var(--space--0) var(--space--4) var(--space--0);
  }
  .ns-client-card-margin {
    margin: var(--space--0) var(--space--0) var(--space--4) var(--space--0);
  }
  .review-section__cards {
    flex-direction: column;
    align-items: center;
  }
  .ethics-section__work-ethics {
    margin-left: 0;
  }
  .ethics-section__work-ethics {
    margin-bottom: var(--space--3);
  }
}
</style>
