import { createRouter, createWebHistory } from "vue-router";
import AboutUs from "../views/AboutUs.vue";
import NotFound from "../views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "aboutus",
    component: AboutUs,
  },
  {
    path: "/jobopenings",
    name: "jobopenings",
    component: () =>
      import(/* webpackChunkName: "job-openings" */ "../views/JobOpenings.vue"),
  },
  // {
  //   path: "/styleguide",
  //   name: "styleguide",
  //   component: () => import("../views/NsStyleguide.vue"),
  // },
  {
    path: "/ourteam",
    name: "ourteam",
    component: () =>
      import(/* webpackChunkName: "our-team" */ "../views/OurTeam.vue"),
  },
  {
    path: "/contactus",
    name: "contactus",
    component: () =>
      import(/* webpackChunkName: "contact-us" */ "../views/ContactUs.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      el: "#go-to-top",
      savedPosition,
      top: "auto",
    };
  },
});

export default router;
