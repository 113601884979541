<template>
  <div :class="['ns-hero', containerSpacingMobile]">
    <h3 class="ns-hero__heading text-ellipsis clamp-3-line">
      {{ title }}
    </h3>
    <p
      v-html="subtitle"
      class="ns-hero__details text-styling text-ellipsis clamp-3-line"
    />
    <img
      class="ns-hero__image"
      width="696"
      height="400"
      :alt="img"
      :src="img"
      rel="preload"
    />
    <div class="ns-hero__extra-content" v-if="hasExtraContentSlot">
      <div class="ns-hero__extra-content--details text-styling">
        <slot name="extraDetails"></slot>
      </div>
      <div class="ns-hero__extra-content--button">
        <slot name="extraButton"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
export default {
  name: "NsHero",
  setup() {
    const containerSpacingMobile = inject(
      "containerSpacingMobile",
      "mobile-spacing"
    );
    return {
      containerSpacingMobile,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "../../assets/images/hero/team-working.svg",
    },
  },
  computed: {
    hasExtraContentSlot() {
      return this.$slots.extraDetails;
    },
  },
};
</script>

<style scoped>
.text-styling {
  color: var(--normal-text--dark);
  letter-spacing: var(--letter-spacing-normal);
}
.ns-hero {
  max-width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ns-hero__heading {
  margin-bottom: var(--space--2);
  max-width: 616px;
  text-align: center;
  color: var(--title-dark--secondary);
}
.ns-hero__details {
  text-align: center;
  max-width: 448px;
  margin-bottom: var(--space--6);
}
.ns-hero__image {
  max-width: 100%;
  max-height: 400px;
}
.ns-hero__extra-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: var(--space--9);
}
.ns-hero__extra-content--details {
  min-width: 320px;
  max-width: 620px;
  flex: 1;
}
.ns-hero__extra-content--button {
  min-width: max-content;
  margin: var(--space--5) var(--space--0) var(--space--5) var(--space--11);
}
@media screen and (max-width: 1024px) {
  .ns-hero__image {
    height: auto;
  }
}
@media screen and (max-width: 856px) {
  .ns-hero__extra-content {
    flex-direction: column;
  }
  .ns-hero__extra-content--button {
    margin: var(--space--5) auto auto;
  }
}
@media screen and (max-width: 640px) {
  .text-styling {
    letter-spacing: var(--letter-spacing-large);
  }
  .ns-hero__heading {
    max-width: 100%;
    margin-bottom: var(--space--1);
    font-size: var(--font-size-m-large);
    line-height: var(--line-height-m-large);
  }
  .ns-hero__details {
    max-width: 90%;
    font-size: var(--font-size-m-xsmall);
    line-height: var(--line-height-m-xsmall);
  }
  .ns-hero__image {
    max-width: 100%;
    height: auto;
  }
  .ns-hero__extra-content {
    margin-top: var(--space--5);
  }
  .ns-hero__extra-content--details {
    min-width: auto;
    flex: 100%;
    font-size: var(--font-size-m-xsmall);
    line-height: var(--line-height-m-xsmall);
  }
  .ns-hero__extra-content--button {
    min-width: 0;
  }
}
</style>
