<template>
  <div class="ns-clientcard-wraper">
    <div
      data-aos="new-animation"
      data-aos-delay="50"
      data-aos-duration="1500"
      data-aos-easing="ease-out-sine"
    >
      <svg :fill="crownColor" class="ns-clientcard__img-section--crown">
        <path
          d="M16.3479 4.24474C15.3022 5.37271 14.3347 6.49417 13.3602 7.47263C11.7944 9.12284 11.1929 9.19863 9.29993 8.01998C8.0905 7.28351 6.88057 6.47551 5.59783 5.59055C5.0021 6.48884 4.55583 7.23705 4.03179 8.05734C3.72966 8.58614 3.3806 9.08729 2.98873 9.55484C2.87214 9.72252 2.72321 9.86572 2.55051 9.9762C2.3778 10.0867 2.18471 10.1623 1.98239 10.1986C1.78007 10.2349 1.5725 10.2313 1.37162 10.1879C1.17073 10.1445 0.980517 10.0622 0.811894 9.94578C0.0561326 9.50412 -0.0274209 8.75966 0.195533 7.93554C0.287417 7.6726 0.410972 7.42157 0.563532 7.18788C1.45662 5.76893 2.34916 4.2725 3.24221 2.84759C4.13526 1.42268 5.03736 1.27324 6.54888 2.15658C8.0604 3.03992 9.42192 3.99584 11.0128 5.02763C11.6818 4.27784 12.2791 3.60603 12.9547 2.92772C16.3119 -0.821343 16.3119 -0.821343 19.8791 2.95609C20.3343 3.47142 20.7891 3.91522 21.3954 4.50696C22.7432 3.53182 24.0915 2.6282 25.3615 1.72513C25.7923 1.39749 26.2426 1.09574 26.7099 0.821504C27.9092 0.139473 28.8129 0.210549 29.5729 1.24823C30.7164 2.87923 31.7827 4.65978 32.8485 6.36286C33.129 6.71711 33.2565 7.16682 33.2033 7.61386C33.15 8.06089 32.9204 8.46897 32.5644 8.74904C31.8155 9.27886 30.7602 9.05985 30.0731 8.09914C29.2398 6.91297 28.4782 5.64881 27.565 4.24267C25.9895 5.36844 24.5695 6.35005 23.1439 7.40323C21.3467 8.67959 20.6718 8.61285 19.155 6.9845C18.1275 6.16824 17.1869 5.25023 16.3479 4.24474Z"
        />
      </svg>
    </div>
    <div class="ns-clientcard">
      <div class="ns-clientcard__img-section">
        <img
          class="ns-clientcard__img-section--img"
          :src="img"
          alt="client img"
        />
        <svg
          :fill="svgColor"
          class="ns-clientcard__img-section--small-scribble"
        >
          <path
            d="M10.5958 4.21938H1.91632C0.795278 4.14356 0.107521 3.70614 0.0249905 2.76131C-0.0575402 1.81647 0.712744 1.30323 1.65497 1.0816C2.33296 0.960774 3.02602 0.911797 3.71824 0.935794C6.6412 0.935794 9.48164 1.01162 12.4046 1.01162C14.8118 1.01162 17.2189 0.935794 19.626 0.935794C20.9122 0.935794 21.6893 1.4432 21.8613 2.31805C21.9113 2.55002 21.903 2.78837 21.8368 3.01743C21.7707 3.24649 21.6482 3.4611 21.4774 3.64722C21.3066 3.83334 21.0914 3.98676 20.8458 4.09742C20.6003 4.20809 20.3299 4.27349 20.0525 4.28936C19.3687 4.37268 18.6797 4.42137 17.9892 4.43517H10.5133C10.5444 4.40628 10.5679 4.37208 10.5821 4.33486C10.5964 4.29764 10.601 4.25826 10.5958 4.21938Z "
          />
        </svg>
        <svg :fill="svgColor" class="ns-clientcard__img-section--scribble">
          <path
            d="M35.6672 0.64123H67.304C67.9928 0.629597 68.6818 0.652965 69.3673 0.711215C69.8009 0.750989 70.2034 0.920832 70.5024 1.19011C70.8014 1.45939 70.9772 1.81039 70.9979 2.17983C71.0187 2.54926 70.8831 2.91281 70.6156 3.20496C70.3481 3.49711 69.9664 3.69864 69.5392 3.77317C68.8612 3.894 68.1682 3.94298 67.4759 3.91898C45.9836 3.84899 24.4912 3.70318 3.08819 3.62736C2.48004 3.69657 1.86127 3.64669 1.27938 3.48156C0.909584 3.38751 0.585349 3.19487 0.354876 2.93222C0.124403 2.66956 0 2.35088 0 2.02348C0 1.69609 0.124403 1.37741 0.354876 1.11475C0.585349 0.852091 0.909584 0.659453 1.27938 0.565407C1.88461 0.273793 2.82684 0.4196 3.60401 0.4196H35.6672V0.64123Z"
          />
        </svg>
      </div>
      <div class="ns-clientcard__client-info">
        <span
          class="ns-clientcard__client-info--name text-ellipsis clamp-1-line"
          >{{ name }}</span
        >
        <span
          class="ns-clientcard__client-info--occupation text-ellipsis clamp-1-line"
          >{{ info }}</span
        >
      </div>
      <p class="ns-clientcard__client-info--review text-ellipsis clamp-5-line">
        {{ review }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "Client name",
    },
    info: {
      type: String,
      default: "Client info",
    },
    review: {
      type: String,
      default: "Client rerview",
    },
    img: {
      type: String,
      default: "",
    },
    svgColor: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "",
    },
    x: {
      type: String,
      default: "",
    },
    y: {
      type: String,
      default: "",
    },
  },
  computed: {
    occuptionColor() {
      return this.bgColor === "var(--client-bg-color--secondary)"
        ? "var(--client-occuption--blue-green)"
        : "var(--client-occuption--cream)";
    },
    crownColor() {
      return this.svgColor === "var(--client-svg-color--secondary)"
        ? "var(--client-crown--blue-green)"
        : "var(--client-crown--orange)";
    },
  },
};
</script>

<style scoped>
[data-aos="new-animation"] {
  transform: translateY(-40px);
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos="new-animation"].aos-animate {
  transform: translateY(0px);
  opacity: 1;
}
.ns-clientcard-wraper {
  position: relative;
}
.ns-clientcard {
  max-width: 409px;
  background: v-bind(bgColor);
  border-radius: 0.5em;
  padding: var(--space--5) var(--space--5) var(--space--5) var(--space--4);
  margin-top: var(--space--4);
  position: relative;
  overflow: hidden;
}

.ns-clientcard__client-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  z-index: 1;
}

.ns-clientcard__client-info--name {
  font-family: var(--headings-and-emphasized--font-family);
  font-weight: var(--font-weight-bold);
  width: 170px;
}

.ns-clientcard__client-info--occupation {
  padding-top: var(--space--1);
  font-size: var(--font-size-m-xsmall);
  line-height: var(--line-height-m-xsmall);
  letter-spacing: var(--letter-spacing-large);
  width: 200px;
  color: v-bind(occuptionColor);
}

.ns-clientcard__client-info--name,
.ns-clientcard__client-info--occupation {
  text-align: right;
}

.ns-clientcard__img-section {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  width: 1px;
  height: 100px;
  z-index: 2;
}
.ns-clientcard__img-section--img {
  clip-path: path(
    "M100.809 60.7681C73.6345 77.7697 16.8406 102.754 -4.75133 120.043H-33.5405V-23.9097H177.58C165.585 1.84646 158.331 24.78 100.809 60.7681Z"
  );
  width: 210px;
  height: 210px;
  border-radius: inherit;
  object-position: v-bind(x) v-bind(y);
  z-index: 0;
}

.ns-clientcard__img-section--small-scribble {
  position: absolute;
  top: var(--space--15);
  left: var(--space--8);
  width: 22px;
  height: 5px;
}

.ns-clientcard__img-section--scribble {
  position: absolute;
  top: var(--space--17);
  left: var(--space--4);
  width: 71px;
  height: 4px;
}

.ns-clientcard__img-section--crown {
  position: absolute;
  left: var(--space--7);
  width: 33px;
  height: 10px;
}

.ns-clientcard__client-info--review {
  color: var(--normal-text--dark);
  font-size: var(--font-size-m-xsmall);
  line-height: var(--line-height-m-xsmall);
  padding-top: var(--space--9);
  letter-spacing: var(--letter-spacing-large);
  position: relative;
  z-index: 3;
}

@media screen and (max-width: 640px) {
  .ns-clientcard {
    padding: var(--space--4) var(--space--4) var(--space--4) var(--space--3);
  }
  .ns-clientcard__client-info--review {
    font-size: var(--font-size-m-xsmall);
    line-height: var(--line-height-m-xsmall);
  }
  .ns-clientcard__client-info--review {
    padding-top: var(--space--9);
    font-size: var(--font-size-m-xxsmall);
    line-height: var(--line-height-m-xxsmall);
  }
  .ns-clientcard__img-section--img {
    clip-path: path(
      "M85.4765 57.2939C62.2127 72.253 13.5921 94.2361 -4.89249 109.448H-29.5386V-17.2115H151.199C140.93 5.45059 134.72 25.6291 85.4765 57.2939Z"
    );
  }
  .ns-clientcard__img-section--small-scribble {
    top: var(--space--14);
    left: var(--space--7);
  }
  .ns-clientcard__img-section--scribble {
    top: var(--space--16);
    left: var(--space--3);
  }
  .clamp-5-line {
    -webkit-line-clamp: unset;
  }
}
@media screen and (max-width: 374px) {
  .ns-clientcard__img-section--img {
    clip-path: path(
      "M244.387 1.31629L245.054 -1.5H244H1H0.5V1V162V162.845L1.24087 162.438C26.7024 148.441 50.0597 137.189 72.0072 126.615C135.679 95.9403 187.486 70.9817 244.387 1.31629Z"
    );
    width: 290px;
    height: 290px;
  }
  .ns-clientcard__client-info {
    margin-top: var(--space--18);
  }
  .ns-clientcard__client-info--review {
    padding-top: var(--space--3);
  }
  .ns-clientcard__img-section--scribble {
    top: var(--space--19);
    left: var(--space--10);
  }
  .ns-clientcard__img-section--small-scribble {
    top: var(--space--17);
    left: var(--space--14);
  }
  .ns-clientcard__img-section--crown {
    left: var(--space--12);
  }
  .ns-clientcard__client-info--occupation,
  .ns-clientcard__client-info--name {
    width: 100%;
  }
}
</style>
